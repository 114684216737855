import classNames from "classnames";
import "./Header.scss";

function Header({ idElement, onSelectMenu, delayAnimation }) {
  return (
    <div className="header" id={idElement}>
      <div className="header-wrapper">
        <div
          className={classNames("header-left", {
            animate: delayAnimation,
          })}
        >
          <div className="title-header">
            Experienced <b>mobile and web</b> applications and website.
          </div>
          <p className="content-header">
            NEXTGEN TECHNOLOGY is a team of experienced mobile and web
            applications and website builders measuring dozens of completed
            projects. We build and develop mobile applications for several top
            platforms, including Android & IOS.{" "}
          </p>
          <button
            type="button"
            className="contact-btn"
            onClick={() => onSelectMenu("contact")}
          >
            Contact us
          </button>
        </div>
        <div
          className={classNames("header-right", {
            animate: delayAnimation,
          })}
        >
          <img
            className="img-cpu"
            alt=""
            src={require("assets/images/icons/cpu.png")}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
