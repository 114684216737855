import Footer from "components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import MainLayout from "layout/MainLayout";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { mainRoutes } from "routes/routes";
import "./App.scss";
import { ToastContainer } from "react-toastify";

function App() {
  const pathname = useLocation().pathname;
  const [menuActive, setMenuActive] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(true);
  const [delayAnimation, setDelayAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setDelayAnimation(true);
    }, 2000);
    setTimeout(() => {
      setDelayAnimation(true);
    }, 4000);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  },[pathname])

  const handleScroll = (e) => {
    setScrollPosition(e.target.scrollTop);
  };

  const handleSelectMenu = (menuSelect) => {
    setMenuActive(menuSelect);
  };

  return (
    <div className="App">
      <div className="landing-container">
        <Navbar
          onSelectMenu={handleSelectMenu}
          menuActive={menuActive}
          scrollPosition={scrollPosition}
        />
        <div className="landing-body" onScroll={(e) => handleScroll(e)}>
          {/* <div className={classNames("img-loading", { hide: !loading })}>
            <img
              src="https://media.discordapp.net/attachments/1003481730423984149/1072832390076047380/loading.gif"
              className="icon-loading"
              alt=""
            />
          </div> */}
          {/* <Content
            menuSelect={menuActive}
            scrollPosition={scrollPosition}
            onSelectMenu={handleSelectMenu}
            delayAnimation={delayAnimation}
          /> */}
          <Routes>
            {mainRoutes.map((route, index) => {
              const Page = route.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <MainLayout>
                      <Page
                        menuSelect={menuActive}
                        scrollPosition={scrollPosition}
                        onSelectMenu={handleSelectMenu}
                        delayAnimation={delayAnimation}
                      />
                    </MainLayout>
                  }
                />
              );
            })}
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </Routes>
          <Footer />
        </div>
      <ToastContainer
        position={"top-right"}
        closeOnClick
        pauseOnFocusLoss
        autoClose={5000}
        hideProgressBar={false}
        limit={3}
        draggable={true}
        style={{ width: "320px" }}
      />
      </div>
    </div>
  );
}

export default App;
