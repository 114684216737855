import { useRef, useState } from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";
import Popup from "components/Popup/Popup";
import PopupSubmitSuccess from "components/Popup/PopupSubmitSuccess/PopupSubmitSuccess";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";

function Contact(props) {
  const { idElement } = props;
  const form = useRef();
  const recaptchaRef = useRef();

  const [showPopup, setShowPopup] = useState(false);
  // const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);

  const [isError, setIsError] = useState(0);
  // const [recaptchaValue, setRecaptchaValue] = useState(null);

  // const handleChangeRecaptchaValue = (value) => {
  //   setIsRecaptchaVerified(true);
  //   setRecaptchaValue(value);
  // };

  const sendEmail = (e) => {
    e.preventDefault();

    const name = e.target.user_name.value;
    const phone = e.target.user_phone.value;
    const email = e.target.user_email.value;
    const message = e.target.message.value;

    // const recaptchaToken = recaptchaValue;

    if (!name.trim()) {
      setIsError(1);
      return;
    }
    if (!phone.trim()) {
      setIsError(2);
      return;
    }
    if (!email.trim()) {
      setIsError(3);
      return;
    }

    toast.success("Sent successfully!");
    e.target.reset();
    // const serviceId = "service_zfv2o7r";
    // const templateId = "template_jmtvadz";
    // const userId = "hzHx0pDVjpp5Eq4-v";

    // const emailParams = {
    //   to_name: "Contact Nextgen technology",
    //   to_email: "contact.nextgentechnology@gmail.com",
    //   user_name: name,
    //   user_email: email,
    //   user_phone: phone,
    //   message: message,
    //   "g-recaptcha-response": recaptchaToken,
    // };
    
    // if (isRecaptchaVerified) {
    //   emailjs.send(serviceId, templateId, emailParams, userId).then(
    //     (result) => {
    //       setShowPopup(true);
    //       setIsError(0);
    //       setIsRecaptchaVerified(false);
    //       setRecaptchaValue(null);
    //       recaptchaRef.current.reset();
    //       console.log(result.text);
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
    //   e.target.reset();
    // } else {
    //   return;
    // }
  };

  return (
    <div className="contact" id={idElement}>
      <h1>Contact Us</h1>
      <p className="slogan">
        We work together with industry experts in their fields to offer
        all-in-one solutions and starting points for your own soulutions.
      </p>
      <form
        encType="multipart/form-data"
        className="form-contact"
        ref={form}
        onSubmit={sendEmail}
      >
        <div className="info-user">
          <div className="input-name">
            <input
              type="text"
              className="input-item"
              placeholder="Enter your name"
              name="user_name"
            />
            {isError === 1 && <span className="error">*Required</span>}
          </div>
          <div className="input-phone">
            <input
              type="number"
              className="input-item"
              placeholder="Enter your phone number"
              name="user_phone"
            />
            {isError === 2 && <span className="error">*Required</span>}
          </div>
        </div>
        <div className="input-email">
          <input
            type="email"
            className="input-item"
            placeholder="Enter your email"
            name="user_email"
          />
          {isError === 3 && <span className="error">*Required</span>}
        </div>
        <textarea
          rows={5}
          className="input-detail"
          placeholder="More detail"
          name="message"
        />
        {/* <ReCAPTCHA
          ref={recaptchaRef}
          onChange={handleChangeRecaptchaValue}
          sitekey="6LdjL3cmAAAAAIyQEWTQ-C2VRk8sGyaQySOzf43Z"
        /> */}
        <button type="submit" className="send-btn">
          SEND US
        </button>
      </form>
      {showPopup && (
        <Popup isShow={showPopup} onClose={() => setShowPopup(false)} center>
          <PopupSubmitSuccess
            onClose={() => setShowPopup(false)}
            content="You have submitted successfully"
          />
        </Popup>
      )}
    </div>
  );
}

export default Contact;
