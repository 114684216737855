import { useNavigate } from "react-router-dom";
import "./Footer.scss";

function Footer() {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="content-footer">
          <div className="main-left">
            <img
              className="heading-logo"
              alt=""
              src={require("assets/images/logos/logo.png")}
            />
            <p className="left-content">
              NEXTGEN TECHNOLOGY is a team of experienced mobile and web
              applications and website builders measuring dozens of completed
              projects
            </p>
          </div>
          <div className="main">
            <h3 className="heading-main">Contact us</h3>
            <div className="main-content flex-column">
              {/* <div className="info-footer flex-row">
              <h5 className="title-info">Phone number: </h5>
            </div> */}
              <div className="info-footer flex-row">
                <h5 className="title-info">
                  Email: contact.nextgentechnology@gmail.com
                </h5>
              </div>
              <div className="info-footer flex-row">
                <h5 className="title-info">
                  Address: Iris Bay Tower, Business Bay, Dubai, UAE
                </h5>
              </div>
            </div>
          </div>
          <div className="main-right">
            <h3 className="heading-main">Jobs</h3>
            <div className="main-content flex-column">
              <div className="info-footer flex-row">
                <h5 className="title-jobs" onClick={() => navigate('job')}>
                  Jobs opening
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="title-footer">
          <p className="copyright">
            Copyright ® 2023 Nextgen Technology
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
